import { Color } from "@/types/Style";
import { StatusType } from "@/types/Subscription";

export const setStatusText = (status: StatusType): string => {
  switch (status) {
    case "SUCCESS": return "Pembayaran Berhasil";
    case "ACCEPT": return "Pembayaran Diterima";
    case "CANCEL": return "Pembayaran Dibatalkan";
    case "PENDING1": return "Menunggu Pembayaran #1";
    case "PENDING2": return "Menunggu Pembayaran #2";
    case "EXP1": return "Pembayaran Kadaluarsa #1";
    case "EXP2": return "Pembayaran Kadaluarsa #2";
    case "DENY": return "Pembayaran Ditolak";
    case "FAIL": return "Pembayaran Gagal";
    default: return status;
  }
}

export const setStatusTextSimpe = (status: StatusType): string => {
  switch (status) {
    case "SUCCESS": return "Pembayaran Berhasil";
    case "ACCEPT": return "Pembayaran Diterima";
    case "CANCEL": return "Pembayaran Dibatalkan";
    case "PENDING1":
    case "PENDING2": return "Menunggu Pembayaran";
    case "EXP1":
    case "EXP2": return "Pembayaran Kadaluarsa";
    case "DENY": return "Pembayaran Ditolak";
    case "FAIL": return "Pembayaran Gagal";
    default: return status;
  }
}

export const setStatusTextColor = (status: StatusType): string => {
  switch (status) {
    case "SUCCESS":
    case "ACCEPT":
      return "text-secondary-hover";
    case "CANCEL":
    case "DENY":
    case "FAIL":
      return "text-danger-main";
    case "PENDING1":
    case "PENDING2":
    case "EXP1":
    case "EXP2":
      return "text-warning-main";
    default: return "text-black";
  }
}

export const setBadgeColor = (status: StatusType): Color => {
  switch (status) {
    case "SUCCESS":
    case "ACCEPT":
      return "secondary"
    case "CANCEL":
    case "DENY":
    case "FAIL":
      return "danger"
    case "PENDING1":
    case "PENDING2":
    case "EXP1":
    case "EXP2":
      return "warning"
    default: return "primary";
  }
}