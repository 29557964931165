/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-one-expression-per-line */
import React, {
  ReactNode,
  useState,
  useEffect,
  useRef,
  LegacyRef,
  useCallback,
} from "react";
import style from "@/styles/datatable.module.scss";
import { closestCenter, DndContext, DragEndEvent } from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import { Pagination } from "@/components/molecules";
import TrSortable from "../atoms/tr-sortable";
import useFilter from "@/store/useFilter";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import SortingTable from "../atoms/SortingTable";
import cn from "classnames";

export type ColumnTable<G> = {
  header: string;
  accessor: keyof (G & { no: string }) | "skip" | "";
  sortTable?: boolean;
  stopPropagation?: boolean;
  accessorComponent?: (data: G) => ReactNode | ReactNode;
  headerStyle?: string;
  isSticky?: boolean;
  isDefaultSortable?: boolean;
}[];

type DataTable_T<T> = {
  data: { data: T[]; total: number } | T[];
  column: ColumnTable<T>;
  DND?: {
    isOn?: boolean; //wajib
    onSubmit?: boolean;
    result: (result: any) => void;
  };
  isHScroll?: boolean;
  gotoDetail?: (id: T) => void;
  hideNoColumn?: boolean;
  prependHead?: ReactNode;
  appendRow?: ReactNode;
  defaultBy?: "asc" | "desc";
  isFetching?: boolean;
  noTextAlign?: string;
  goGrabberHidden?: boolean;
  onSort?: (sort: string, by: string) => void;
  refetch?: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<AxiosResponse<any, any>, unknown>>;
  individualHeightL?: string;
  individualHeightM?: string;
  paginationClickScrollTop?: boolean;
};

const Table = <T,>({
  data,
  column,
  DND,
  prependHead,
  appendRow,
  defaultBy = "desc",
  gotoDetail,
  hideNoColumn = false,
  isHScroll = false,
  isFetching,
  noTextAlign = "text-center",
  goGrabberHidden = false,
  refetch,
  onSort,
  individualHeightL,
  individualHeightM,
  paginationClickScrollTop,
}: DataTable_T<T>) => {
  const [hover, setHover] = useState<string>();
  const { filter, setFilter } = useFilter();
  const [height, setHight] = useState<any>();

  const handleSort = (v: string) => {
    if (v != filter.sort) {
      setFilter({ sort: v, by: "desc" }).then(() => {
        refetch && refetch();
        onSort && onSort(v, "desc");
      });
    } else {
      if (filter.by == "asc") {
        setFilter({ by: "desc" }).then(() => {
          refetch && refetch();
          onSort && onSort(v, "desc");
        });
      } else {
        setFilter({ by: "asc" }).then(() => {
          refetch && refetch();
          onSort && onSort(v, "asc");
        });
      }
    }
  };

  useEffect(() => {
    const handleInnerHeight = () => {
      if (!isFetching) {
        setTimeout(() => {
          const hScreen = window.innerHeight;
          const tableElementHeight = document.getElementById("tabel");
          const screenSize = hScreen > 1000 ? "L" : "M";

          let hTable = 0;
          let isHTableOverflow = true;

          if (tableElementHeight) {
            hTable = tableElementHeight.clientHeight;
            isHTableOverflow =
              screenSize == "L"
                ? hTable > Math.floor((hScreen * 55) / 100)
                : hTable > Math.floor((hScreen * 44) / 100);
          }

          setHight(
            screenSize == "L" && isHTableOverflow
              ? individualHeightL ?? "h-[55vh]"
              : screenSize == "M" && isHTableOverflow
              ? individualHeightM ?? "h-[44vh]"
              : ""
          );
        }, 10);
      }
    };
    handleInnerHeight();

    window.addEventListener("resize", handleInnerHeight);

    return () => {
      window.removeEventListener("resize", handleInnerHeight);
    };
  }, [isFetching]);

  // DND state
  const [activeId, setActiveId] = useState(null);
  const [record, setRecord] = useState<any>();

  useEffect(() => {
    // do mutate when dnd onsubmit true
    if (DND?.onSubmit) {
      // @ts-ignore
      DND.result(record);
    }
  }, [DND]);

  useEffect(() => {
    // @ts-ignore
    if (Array.isArray(data)) {
      setRecord(data);
    } else {
      setRecord(data.data);
    }
  }, [data, DND?.isOn]);

  const handleDragEnd = (e: DragEndEvent) => {
    const { active, over }: any = e;

    if (active.id !== over.id) {
      // NOTE : do mutate here

      setRecord((items: any) => {
        const activeIndex = items.findIndex(
          (item: any) => item.id == active.id
        );
        const overIndex = items.findIndex((item: any) => item.id == over.id);
        const move = arrayMove(items, activeIndex, overIndex);

        return move;
      });
    }
    setActiveId(null);
  };
  if (isFetching) return <>Loading...</>;

  if (Array.isArray(data) ? data.length === 0 : data.data.length === 0)
    return (
      <div className="pt-24">
        <img src="/empty-state/table-no-data.svg" className="mx-auto" />
        <div className="mx-auto text-center text-xl font-semibold">
          Tidak ada data
        </div>
      </div>
    );

  return (
    <div className={cn(height)}>
      <div className="relative h-full overflow-auto pb-4" id="tablewrapper">
        <DndContext
          collisionDetection={closestCenter}
          onDragStart={(e: any) => setActiveId(e.active.id)}
          onDragEnd={handleDragEnd}
        >
          {record && (
            // @ts-ignore
            <SortableContext items={record}>
              <table
                id="tabel"
                className={`${style.table} ${
                  isHScroll ? "w-max min-w-full" : "w-full"
                }`}
              >
                <thead>
                  {prependHead}
                  <tr>
                    {!hideNoColumn && (
                      <th className={`sticky left-0 capitalize`}>
                        <p className={`${noTextAlign}`}>No</p>
                      </th>
                    )}
                    {column.map((v, i) => {
                      if (v.accessor == "skip") return null;
                      return (
                        // @ts-ignore
                        <th
                          key={i}
                          className={`${v.headerStyle} ${
                            v.isSticky && "sticky left-0"
                          }`}
                          onMouseOver={() => setHover(v.accessor.toString())}
                          onMouseLeave={() => setHover(undefined)}
                        >
                          <button
                            className="flex items-center justify-between gap-5 capitalize"
                            disabled={isFetching}
                            onClick={() =>
                              v.sortTable && handleSort(v.accessor.toString())
                            }
                          >
                            <p>{v.header}</p>
                            {v.sortTable && (
                              <SortingTable
                                accessor={v.accessor}
                                hover={hover}
                                defaultBy={defaultBy}
                                defaultSort={v.isDefaultSortable}
                              />
                            )}
                          </button>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {record &&
                    record?.map((datas: any, i: number) => {
                      return (
                        <TrSortable
                          key={i}
                          id={datas.id}
                          enable={DND?.isOn}
                          onClick={() => {
                            gotoDetail && gotoDetail(datas);
                          }}
                          idx={
                            !hideNoColumn &&
                            i +
                              filter.page * filter.per_page -
                              filter.per_page +
                              1
                          }
                          dnd={DND?.isOn ? true : false}
                          goGrabberHidden={goGrabberHidden}
                        >
                          {column.map((c, index) => {
                            if (c.accessor == "skip") return null;
                            //@ts-ignore
                            var fieldData: any = datas[c.accessor] as any;

                            return (
                              <td
                                key={index}
                                onClick={(e) => {
                                  if (c.stopPropagation == true) {
                                    e.stopPropagation();
                                  }
                                }}
                                className={`${c.isSticky && "sticky left-0"}`}
                              >
                                {!!c.accessorComponent
                                  ? c.accessorComponent(datas)
                                  : fieldData ?? "-"}
                              </td>
                            );
                          })}
                        </TrSortable>
                      );
                    })}
                  {appendRow}
                </tbody>
              </table>
            </SortableContext>
          )}
        </DndContext>
      </div>
      {/* pagination */}
      {/* @ts-ignore */}
      {data.total && (
        <div className="my-8 flex select-none justify-end">
          <Pagination
            paginationClickScrollTop={paginationClickScrollTop}
            // @ts-ignore
            data={data.total}
            refetch={refetch}
            isFetching={isFetching}
          />
        </div>
      )}
      {/* emdpagination */}
    </div>
  );
};

export default Table;
