import { FormikHelpers, Formik, FormikValues } from "formik";
import Button from "../atoms/Button";
import Inputfield from "../atoms/InputField";
import Textarea from "../atoms/Textarea";
import * as Yup from "yup"
import { TFormFieldModal, TFormFieldModalFile } from "plugins/openModalFormField";
import InputUploadFile from "../atoms/InputUploadFile";

type TModalForm<T> = {

   onSubmit: (params: {
      value: T,
      helpers: FormikHelpers<T>,

   }) => void;
   listFields: (TFormFieldModal | TFormFieldModalFile)[]
   title?: string,
   initValue: T
   buttonTitle?: string,
   validationSchema?: any,
}

const ModalForm = <T extends FormikValues>(params: TModalForm<T>) => {

   const {
      validationSchema = Yup.object({
         field: Yup.string().required("Tidak boleh kosong")
      })
      , buttonTitle = "Simpan",
      onSubmit,
      listFields,
      initValue,
      title = "Masukkan alasan melakukan unblokir akun"
   } = params;

   //    const isFieldModal = (value: TFormFieldModal | TFormFieldModalFile): value is TFormFieldModal => { //magic happens here
   //       return (<Fish>pet).swim !== undefined;
   //   }

   const isFileModalType = (value: TFormFieldModal | TFormFieldModalFile): value is TFormFieldModalFile => {
      return (value as TFormFieldModalFile).type === "file"
   }

   return (

      <Formik<T>
         initialValues={initValue}
         validationSchema={validationSchema}
         onSubmit={(value, helpers,) => {
            onSubmit({
               value: value,
               helpers: helpers
            })
         }}
      >
         {
            ({ values, setFieldValue, handleSubmit, errors }) => {
               return (
                  <div className='flex flex-col px-[40px] w-[634px]'>
                     <div className="mb-5 text-2xl text-white-100">
                        {title}
                     </div>

                     {
                        listFields.map((item, index) => {

                           var error = (errors as Record<string, string>)[item.name];

                           if ((isFileModalType(item))) {
                              return (
                                 <div
                                    className='py-3'
                                    key={index}
                                 >
                                    <InputUploadFile
                                       id={item.name}
                                       onChange={(e: any) => {
                                          setFieldValue(item.name, e.target.files[0])
                                       }}
                                       error={error}
                                       value={values[item.name]}
                                       maksimalUpload={item.maksimalUpload}
                                       title={item.title}
                                       description={item.description}
                                       accept={item.accept}
                                       defaultValue={item.defaultValue}
                                    />
                                 </div>
                              )
                           } else {

                              if (item.type == "input") {

                                 return (
                                    <div
                                       className='py-3'
                                       key={index}
                                    >
                                       {/* <Inputfield value={values[item.name]} id='field' placeholder={item.placeholder} error={error} onChange={(e) => setFieldValue('field', e.currentTarget.value)} /> */}
                                       <Inputfield
                                          value={values[item.name]}
                                          id={item.name}
                                          placeholder={item.placeholder}
                                          error={error}
                                          onChange={(e) => setFieldValue(item.name, e.currentTarget.value)}
                                       />
                                       {/* <div className='text-left text-danger-main text-sm mt-1'>
   
                                    {!!error ? <div>{error}</div> : ""}
                                 </div> */}
                                    </div>
                                 )

                              } else {

                                 return (
                                    <div
                                       className='py-3'
                                       key={index}
                                    >
                                       {/* <Textarea id='field' value={values[item.name]} onChange={(e) => setFieldValue('field', e.currentTarget.value)} error={error} placeholder={item.placeholder} rows={2} /> */}
                                       <Textarea
                                          id='field'
                                          value={values[item.name]}
                                          onChange={(e) => setFieldValue(item.name, e.currentTarget.value)}
                                          error={error}
                                          placeholder={item.placeholder}
                                          rows={2}
                                       />
                                       {/* <div className='text-left text-danger-main text-sm mt-1'>
   
                                    {!!error ? <div>{error}</div> : ""}
                                 </div> */}

                                    </div>
                                 )

                              }
                           }

                        })
                     }

                     <Button
                        addClassName='w-full'
                        onClick={() => handleSubmit()}
                     >
                        {buttonTitle}
                     </Button>
                  </div>
               )
            }
         }

      </Formik>
   )
}

export default ModalForm