import { ReactNode } from "react";
import { useSpring, animated, config } from "react-spring";
import { IoCloseSharp } from "react-icons/io5";

export interface ModalOptions {
  isError: boolean;
  disableBackdropClose: boolean;
  forcedCloseCallback: () => void;
}
interface ModalProps {
  onClose: () => void;
  options?: Partial<ModalOptions>;
  message: ReactNode | ((close: () => Promise<void>) => ReactNode);
}

const ModalBase = ({ onClose, options, message }: ModalProps) => {
  var overlayIsClicked = false;

  const [scale, scaleApi] = useSpring(
    {
      from: {
        opacity: 0,
        scale: 0,
      },
      to: {
        opacity: 1,
        scale: 1,
      },
      reset: true,
      config: config.wobbly,
    },
    []
  );
  const [fade, fadeApi] = useSpring(
    {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
      reset: true,
    },
    []
  );

  /**
   * Function to call leave animation
   */
  const fadeOut = () => {
    return new Promise<void>((resolve) => {
      if (overlayIsClicked) return;

      overlayIsClicked = true;
      scaleApi.start({
        reverse: true,
        onRest: () => {
          onClose();
          resolve();
        },
      });
      fadeApi.start({
        reverse: true,
      });
    });
  };
  return (
    <animated.div
      onClick={(e) => {
        options?.disableBackdropClose || fadeOut();
        options?.disableBackdropClose || options?.forcedCloseCallback?.();
      }}
      className="fixed inset-0 flex justify-center items-center z-20 bg-[#32323280]"
      style={fade}
    >
      <animated.div
        style={scale}
        className="min-w-[434px]  rounded-xl"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className={`bg-neutral-300  flex justify-end  pt-2 pr-2 rounded-t-xl`}
        >
          <IoCloseSharp
            className="text-white-100 w-6 h-6 cursor-pointer"
            onClick={() => fadeOut()}
          />
        </div>

        <div className="px-4 pb-5 pt-2 text-center min-h-[109px] bg-neutral-300 rounded-b-xl text-white-100">
          {message instanceof Function ? message(() => fadeOut()) : message}
        </div>
      </animated.div>
    </animated.div>
  );
};

export default ModalBase;
