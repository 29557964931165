import { useEffect, useMemo, useState } from "react";

type dateFn = () => number;

export const daysName = ["Sen", "Sel", "Rab", "Kam", "Jum", "Sab", "Min"];
export const monthName = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

export type DateTypes = {
  day: number;
  month: number;
  year: number;
};

export const useMapDate = (
  date: Omit<DateTypes, "day">
): {
  mapDate: DateTypes[];
  prevYear: dateFn;
  prevYearMonth: dateFn;
  nextYear: dateFn;
  nextYearMonth: dateFn;
} => {
  const prevYear = () => (date.month === 0 ? date.year - 1 : date.year);
  const prevYearMonth = () => (date.month === 0 ? 11 : date.month - 1);

  const nextYear = () => (date.month === 11 ? date.year + 1 : date.year);
  const nextYearMonth = () => (date.month === 11 ? 0 : date.month + 1);

  const mapDate = useMemo(() => {
    let daysInAMonth = 32 - new Date(date.year, date.month, 32).getDate();

    // Get the first day value, 0=sunday, 6=saturday
    let firstDay = new Date(date.year, date.month).getDay();

    // Empty array to store the map date
    let dateArray: DateTypes[] = [];
    let startDay = 1;

    let additionalFirstDay = firstDay === 0 ? 6 : firstDay - 1;

    // Get value of the last month date, minus additional day + 1 to iterate from there
    let lastMonthDay =
      32 -
      new Date(
        date.month === 0 ? date.year - 1 : date.year,
        date.month === 0 ? 11 : date.month - 1,
        32
      ).getDate() -
      additionalFirstDay +
      1;

    // Value needed for the remaining calendar
    let additionalLastDay =
      7 - new Date(date.year, date.month, daysInAMonth).getDay();

    let nextMonthDay = 1;

    for (
      let i = 0;
      i <
      additionalFirstDay +
        daysInAMonth +
        (additionalLastDay === 7 ? 0 : additionalLastDay);
      i++
    ) {
      // This month date
      if (i >= additionalFirstDay && i < additionalFirstDay + daysInAMonth) {
        dateArray.push({
          day: startDay++,
          month: date.month,
          year: date.year,
        });
      }
      // Previous month date
      else if (i < additionalFirstDay) {
        dateArray.push({
          day: lastMonthDay++,
          month: prevYearMonth(),
          year: prevYear(),
        });
      }
      // Future month date
      else {
        dateArray.push({
          day: nextMonthDay++,
          month: nextYearMonth(),
          year: nextYear(),
        });
      }
    }

    return dateArray;
  }, [date]);

  return { mapDate, prevYear, prevYearMonth, nextYear, nextYearMonth };
};

export const useLocalDate = (
  value?: Date|null,
  startDate?: Date|null,
  endDate?: Date|null
) => {
  const setDate = (date: Date) => ({
    day: date?.getDate(),
    month: date?.getMonth(),
    year: date?.getFullYear(),
  });

  const [selectedDate, setSelectedDate] = useState<DateTypes | undefined>();
  useEffect(() => {
    setSelectedDate(value ? setDate(value) : undefined);
  }, [value]);

  const [localStartDate, setLocalStartDate] = useState<DateTypes | undefined>();
  useEffect(() => {
    setLocalStartDate(startDate ? setDate(startDate) : undefined);
  }, [startDate]);

  const [localEndDate, setLocalEndDate] = useState<DateTypes | undefined>();
  useEffect(() => {
    setLocalEndDate(endDate ? setDate(endDate) : undefined);
  }, [endDate]);

  return {
    selectedDate,
    localStartDate,
    localEndDate,
    setSelectedDate,
    setLocalStartDate,
    setLocalEndDate,
  };
};
