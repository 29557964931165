import React, { LegacyRef, ReactNode, memo, useState } from "react";
import { AiFillEyeInvisible, AiFillEye, AiOutlineSearch } from "react-icons/ai";
import { MdCancel } from "react-icons/md";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { Size } from "types/Style";
import ErrorField from "./ErrorField";

export type InputType = {
  id?: string;
  placeholder?: string;
  type?: "text" | "password" | "search" | "number";
  value?: string | number | readonly string[] | undefined;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  error?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
  size?: Size;
  prepend?: ReactNode;
  append?: string;
  appendIcon?: ReactNode;
  isComplete?: boolean;
  isNegative?: boolean;
  isPositive?: boolean;
  isIncomplete?: boolean;
  isTyping?: boolean;
  color?: "secondary" | "primary";
  onClickIcon?: React.MouseEventHandler<SVGElement>;
  addClassName?: string;
  innerRef?: LegacyRef<HTMLInputElement>;
};

function Input({
  id,
  placeholder,
  type = "text",
  value,
  onChange,
  error,
  onBlur,
  disabled,
  size = "sm",
  prepend,
  append,
  isComplete,
  isNegative,
  isPositive,
  readOnly,
  isIncomplete,
  isTyping,
  color = "secondary",
  onClickIcon,
  addClassName,
  appendIcon,
  innerRef,
  ...additionalProps
}: InputType &
  Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "size" | "type"
  >) {
  const [showPassword, setShowPassword] = useState(false);
  const [typing, setTyping] = useState(false);

  const handleStyleDisabled = () => {
    if (disabled) {
      return "bg-white-500 text-white-200";
    } else if (color === "primary") {
      return "bg-neutral-300 text-white-100";
    } else {
      return "bg-white-600 text-white-100";
    }
  };

  const handleFontSize = () => {
    switch (size) {
      case "sm":
        return "text-sm";
      case "md":
        return "text-base";
      case "md":
        return "text-lg";
      default:
        return;
    }
  };

  const handleStyleSize = () => {
    switch (size) {
      case "sm":
        return `text-sm py-2`;
      case "md":
        return `text-base py-3`;
      case "lg":
        return `text-lg py-4`;
      default:
        return;
    }
  };

  const handleStyleBorder = () => {
    if (error || isNegative) {
      return "border-2 border-danger-main";
    } else if (isComplete) {
      return "border-2 border-secondary-hover";
    } else if (disabled) {
      return "border-2 border-white-500";
    } else if (color === "primary") {
      return "border-2 border-primary-main bg-neutral-300";
    } else {
      return "border-2 border-white-600 hover:border-neutral-500 focus:border-neutral-500";
    }
  };

  const handleStylePadding = () => {
    if (
      type === "password" ||
      type === "search" ||
      isComplete ||
      isIncomplete ||
      isPositive ||
      isNegative ||
      isTyping
    ) {
      switch (size) {
        case "sm":
          return "pl-3 pr-[50px]";
        case "md":
          return "pl-4 pr-[50px]";
        case "lg":
          return "pl-4 pr-[50px]";
        default:
          break;
      }
    } else if (append || appendIcon) {
      switch (size) {
        case "sm":
          return "pl-3 pr-[90px]";
        case "md":
          return "pl-4 pr-[90px]";
        case "lg":
          return "pl-4 pr-[90px]";
        default:
          break;
      }
    } else if (prepend) {
      switch (size) {
        case "sm":
          return "pr-3 pl-[50px]";
        case "md":
          return "pr-4 pl-[50px]";
        case "lg":
          return "pr-4 pl-[50px]";
        default:
          break;
      }
    } else {
      switch (size) {
        case "sm":
          return "px-3";
        case "md":
          return "px-4";
        case "lg":
          return "px-4";
        default:
          break;
      }
    }
  };

  const handleType = () => {
    if (type === "password") {
      if (showPassword) {
        return "text";
      } else {
        return "password";
      }
    } else {
      return "text";
    }
  };

  return (
    <div className="min-w-0 flex-1 ">
      <div className="relative">
        <input
          ref={innerRef}
          autoComplete="none"
          type={handleType()}
          className={`${handleStyleDisabled()} ${handleStyleSize()} ${handleStyleBorder()} ${handleStylePadding()} border-box rounded-[5px] outline-none ${
            addClassName ?? "w-full"
          }`}
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          readOnly={readOnly}
          disabled={disabled}
          onKeyUp={() => {
            if (isTyping) {
              setTyping(true);
              setTimeout(() => {
                setTyping(false);
              }, 1000);
            }
          }}
          {...additionalProps}
        />
        {/* handle password */}
        <div className={`absolute top-1/2 right-5 z-[5] -translate-y-1/2`}>
          {type === "password" &&
            (showPassword ? (
              <AiFillEye
                className={`cursor-pointer text-[22px] text-white-0`}
                onClick={() => setShowPassword(false)}
              />
            ) : (
              <AiFillEyeInvisible
                className={`cursor-pointer text-[22px] text-white-0`}
                onClick={() => setShowPassword(true)}
              />
            ))}
        </div>

        {/* handle search */}
        <div className={`absolute top-1/2 right-5 z-[5] -translate-y-1/2`}>
          {type === "search" && (
            <AiOutlineSearch
              className={`cursor-pointer text-[22px] text-white-500`}
              onClick={onClickIcon}
            />
          )}
        </div>

        {/* handle isComplete isNegative isPositive isIncomplete isTyping */}
        <div
          className={`absolute top-1/2 right-5 z-[6] -translate-y-1/2 bg-white-600`}
        >
          {isComplete && (
            <MdCancel
              className="text-[22px] text-white-0"
              onClick={onClickIcon}
            />
          )}
          {isNegative && (
            <MdCancel
              className="text-[22px] text-white-0"
              onClick={onClickIcon}
            />
          )}
          {isPositive && (
            <AiOutlineCheck
              className="text-[22px] text-secondary-hover"
              onClick={onClickIcon}
            />
          )}
          {isIncomplete && (
            <AiOutlineClose
              className="text-[22px] text-danger-main"
              onClick={onClickIcon}
            />
          )}
          {typing && (
            <MdCancel
              className="text-[22px] text-white-0"
              onClick={onClickIcon}
            />
          )}
        </div>

        {/* prepend */}
        {prepend && (
          <div
            className={`absolute top-1/2 z-[5] bg-white-600 text-white-400 ${
              size === "sm" ? "left-4" : "left-5"
            } -translate-y-1/2 ${handleFontSize}`}
          >
            {prepend}
          </div>
        )}

        {/* append */}
        {append && (
          <div
            className={`width-[200px] absolute top-0 bottom-0 right-0 z-[7] rounded-r-[5px] bg-white-400 text-white-600`}
          >
            <div className="flex h-full w-[77px] items-center justify-center">
              {append}
            </div>
          </div>
        )}

        {/* Append-add something in the end- an Icon or text */}
        {!!appendIcon && (
          <div
            className={`absolute z-[5] ${
              disabled ? "bg-white-500" : "bg-white-600"
            } ${disabled ? "text-white-200" : "text-white-100"} top-1/2 ${
              size === "sm" ? "right-4" : "right-5"
            } -translate-y-1/2 ${handleFontSize}`}
          >
            {appendIcon}
          </div>
        )}
      </div>
      {error && <ErrorField error={error} />}
    </div>
  );
}

export default memo(Input);
