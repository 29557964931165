import React, { memo } from 'react'

function ErrorField({error}:{error?:string}) {
  return (
    <p className="text-danger-main text-sm mt-2 text-left">
        {error}
    </p>
  )
}

export default memo(ErrorField)