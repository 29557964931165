import {
  DateTypes,
  daysName,
  monthName,
  useLocalDate,
  useMapDate,
} from "hooks/useCalendar";
import React, { DetailedHTMLProps, useEffect, useMemo, useState } from "react";
import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import Button from "@/components/atoms/Button";
import SelectYearAndMonth from "../atoms/Calendar/SelectYearAndMonth";
import { addDays } from "date-fns";

export type CalendarProps = {
  value?: Date | null;
  selectRange?: boolean;
  startDate?: Date | null;
  endDate?: Date | null;
  onChange?: (startDate?: Date | null, endDate?: Date | null) => void;
  onClose?: () => void;
  minDate?: Date;
  maxDate?: Date;
  className?: string;
  limitRange?: number;
};

const Calendar = ({
  value,
  onChange,
  onClose,
  selectRange = false,
  startDate,
  endDate,
  minDate,
  maxDate,
  className,
  limitRange,
  ...additionalAttributes
}: CalendarProps &
  Omit<
    DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    "onChange" | "className"
  >) => {
  //#region State
  const [date, setDate] = useState<Omit<DateTypes, "day">>({
    year:
      value?.getFullYear() ||
      startDate?.getFullYear() ||
      new Date().getFullYear(),
    month: value?.getMonth() || startDate?.getMonth() || new Date().getMonth(),
  });

  //?State to determine the visibility of the popper
  const [selectIsOpen, setSelectIsOpen] = useState(false);

  // Hold temporary valuee to fall back in case the input(popper) is invalid
  const [tempYear, setTempYear] = useState(
    value?.getFullYear() || startDate?.getFullYear() || new Date().getFullYear()
  );

  // General local component state
  // Value can be changed by according set events
  // or through the change of value, startDate, or endDate
  const {
    selectedDate,
    localStartDate,
    localEndDate,
    setSelectedDate,
    setLocalStartDate,
    setLocalEndDate,
  } = useLocalDate(value, startDate, endDate);

  //#endregion

  //#region Hooks

  // General date info hook
  const { mapDate, prevYear, prevYearMonth, nextYear, nextYearMonth } =
    useMapDate(date);

  // Handle click outside the popper

  useEffect(() => {
    // ----------1970+++++++++++++++2077-------------
    if (date.year < 1970 || date.year > 2077) {
      setDate({ ...date, year: new Date().getFullYear() });
    }
  }, [date]);
  //#endregion

  //#region Function

  // Arrow to switch month on top right of the calendar
  const prevMonth = () => setDate({ year: prevYear(), month: prevYearMonth() });
  const nextMonth = () => setDate({ year: nextYear(), month: nextYearMonth() });

  // Convert the object to Date type
  const convertToDate = (day: DateTypes | DateTypes) =>
    new Date(day.year, day.month, day.day);

  const determineMonth = (day: DateTypes) => {
    if (prevYearMonth() === day.month) return prevYearMonth();
    else if (nextYearMonth() === day.month) return nextYearMonth();
    else return day.month;
  };
  const determineYear = (day: DateTypes) => {
    if (prevYearMonth() === day.month) return prevYear();
    else if (nextYearMonth() === day.month) return nextYear();
    else return day.year;
  };

  // Handle single date click
  const onDateClick = (day: DateTypes) => {
    // Standard picker guard
    if (!selectRange) {
      setSelectedDate({
        day: day.day,
        month: determineMonth(day),
        year: determineYear(day),
      });
      return;
    }

    // When both either start and end is exist or not exist
    if (
      (!localStartDate && !localEndDate) ||
      (localStartDate && localEndDate)
    ) {
      setLocalStartDate({
        day: day.day,
        month: determineMonth(day),
        year: determineYear(day),
      });

      // Also reset endDate if it exist
      localEndDate && setLocalEndDate(undefined);
    }
    // Only localStartDate is defined
    else if (localStartDate && !localEndDate) {
      // When user tryna pick before the start date guard
      if (convertToDate(day) < convertToDate(localStartDate)) {
        setLocalStartDate({
          day: day.day,
          month: determineMonth(day),
          year: determineYear(day),
        });

        return;
      }

      setLocalEndDate({
        day: day.day,
        month: determineMonth(day),
        year: determineYear(day),
      });
    }
  };

  // Handle save button click
  const saveDate = () => {
    if (selectRange) {
      onChange?.(
        convertToDate(localStartDate!),
        localEndDate ? convertToDate(localEndDate) : undefined
      );
      return;
    }
    selectedDate && onChange?.(convertToDate(selectedDate));
  };

  const resetDate = () => {
    setLocalEndDate(undefined);
    setLocalStartDate(undefined);
    setSelectedDate(undefined);

    if (selectRange) {
      onChange?.(undefined, undefined);
      return;
    }
    selectedDate && onChange?.(undefined);
  };

  // Determine whether the day should be highlighted
  const shouldHighlight = (day: DateTypes) => {
    // Single date highlight
    if (
      selectedDate?.day === day.day &&
      selectedDate?.month === day.month &&
      selectedDate?.year === day.year
    )
      return true;

    if (selectRange) {
      return (
        (!!localStartDate &&
          convertToDate(localStartDate).setHours(0, 0, 0, 0) ==
            convertToDate(day).setHours(0, 0, 0, 0)) ||
        (!!localEndDate &&
          convertToDate(localEndDate).setHours(0, 0, 0, 0) ==
            convertToDate(day).setHours(0, 0, 0, 0))
      );
    }

    return false;
  };

  const cleanMinDate = useMemo(() => {
    if (!minDate) return null;
    return minDate.setHours(0, 0, 0, 0);
  }, [minDate]);

  const cleanMaxDate = useMemo(() => {
    if (!maxDate) return null;
    return maxDate.setHours(0, 0, 0, 0);
  }, [maxDate]);

  const outsideAllowedRange = (day: DateTypes) => {
    let convertedDate = convertToDate(day).setHours(0, 0, 0, 0);
    if (cleanMinDate && !cleanMaxDate) return convertedDate < cleanMinDate;
    else if (!cleanMinDate && cleanMaxDate) return convertedDate > cleanMaxDate;
    else if (cleanMinDate && cleanMaxDate)
      return convertedDate < cleanMinDate || convertedDate > cleanMaxDate;

    if (selectRange && !!localStartDate && !!limitRange)
      return (
        convertedDate >
        addDays(convertToDate(localStartDate), limitRange).setHours(0, 0, 0, 0)
      );

    return false;
  };

  const betweenRange = (day: DateTypes) => {
    if (selectRange && localStartDate && localEndDate)
      return (
        convertToDate(day) > convertToDate(localStartDate) &&
        convertToDate(day) < convertToDate(localEndDate)
      );
    return false;
  };

  //#endregion

  return (
    <div
      className={`grid select-none grid-cols-7 bg-black px-3 py-4 text-white-0 ${className}`}
      {...additionalAttributes}
    >
      {/* Header */}
      <div className="monthandyear relative col-span-5 pb-4">
        <div
          className="cursor-pointer text-xl font-semibold"
          onClick={(e) => {
            e.stopPropagation();
            setSelectIsOpen(!selectIsOpen);
          }}
        >
          {monthName[date.month]} {date.year}
        </div>
        {/* Select year and yadda yadda */}
        <SelectYearAndMonth
          show={selectIsOpen}
          date={date}
          setDate={setDate}
          tempYear={tempYear}
          setTempYear={setTempYear}
          onClickOutside={() => selectIsOpen && setSelectIsOpen(false)}
        />
      </div>

      {/* -------------------------------------------------------------------------- */
      /*                     Arroww to change the month                              */
      /* -------------------------------------------------------------------------- */}
      <div className="arrow col-span-2 flex items-center justify-end pb-4">
        <MdChevronLeft
          className="mr-1 cursor-pointer rounded-full bg-white-0 text-2xl text-primary-main"
          onClick={() => prevMonth()}
        />
        <MdChevronRight
          className="cursor-pointer rounded-full bg-white-0 text-2xl text-primary-main"
          onClick={() => nextMonth()}
        />
      </div>

      {/* Map the date name */}
      {daysName.map((day, index) => (
        <div key={index} className="days p-1 text-center">
          {day}
        </div>
      ))}

      {/*-------------------------------------------------------------------------- */
      /*                                  THE DATE                                  */
      /* -------------------------------------------------------------------------- */}
      {mapDate.map((day, index) => {
        let isOutsideAllowedRange = outsideAllowedRange(day);
        return (
          <div
            key={index}
            className={` ${
              shouldHighlight(day)
                ? "bg-primary-pressed"
                : `hover:bg-neutral-100/10 ${
                    (day.month !== date.month || isOutsideAllowedRange) &&
                    "text-white-500"
                  }`
            } ${betweenRange(day) && "bg-primary-focus"} ${
              isOutsideAllowedRange ? "cursor-not-allowed" : "cursor-pointer"
            } py-1 text-center `}
            onClick={() => !isOutsideAllowedRange && onDateClick(day)}
          >
            {day.day}
          </div>
        );
      })}

      <div className="col-span-7 flex justify-end space-x-1 px-2 pt-4">
        <Button
          size="sm"
          color="primary-text"
          disabled={selectRange ? !localStartDate : !selectedDate}
          onClick={() => resetDate()}
        >
          Reset
        </Button>

        <div className="w-full"></div>

        <Button size="sm" color="primary-text" onClick={() => onClose?.()}>
          Tutup
        </Button>

        <Button
          size="sm"
          disabled={selectRange ? !localStartDate : !selectedDate}
          onClick={() => saveDate()}
        >
          Pilih
        </Button>
      </div>
    </div>
  );
};

export default Calendar;
