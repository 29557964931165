import React, { memo } from "react";
import ErrorField from "./ErrorField";

export type TextareaType = {
  id?: string;
  value?: string;
  placeholder?: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  error?: string;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  disabled?: boolean;
  rows?: number;
  color?: "secondary" | "primary";
  name?: string;
  maxLength?: number;
};

function Textarea({
  value,
  onChange,
  id,
  error,
  onBlur,
  placeholder,
  rows = 4,
  disabled,
  color = "secondary",
  name,
  maxLength,
}: TextareaType) {
  const handleStyleDisabled = () => {
    if (disabled) {
      return "bg-white-500 text-white-200";
    } else if (color === "primary") {
      return "bg-neutral-300 text-white-100";
    } else {
      return "bg-white-600 text-white-100";
    }
  };

  const handleStyleBorder = () => {
    if (error) {
      return "border-2 border-danger-main";
    } else if (disabled) {
      return "border-2 border-white-500";
    } else if (color === "primary") {
      return "border-2 border-primary-main bg-neutral-300";
    } else {
      return "border-2 border-white-600 hover:border-neutral-500 focus:border-neutral-500";
    }
  };

  return (
    <div>
      <textarea
        maxLength={maxLength}
        name={name}
        value={value}
        onChange={onChange}
        id={id}
        onBlur={onBlur}
        placeholder={placeholder}
        rows={rows}
        disabled={disabled}
        className={`block w-full rounded-[5px] p-2 outline-none ${handleStyleDisabled()} ${handleStyleBorder()}`}
      />
      {error && <ErrorField error={error} />}
    </div>
  );
}

export default memo(Textarea);
