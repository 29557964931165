import { useState, useEffect, useRef } from "react";
import Calendar from "@/components/molecules/Calendar";
import Button from "@/components/atoms/Button";
import InputField from "@/components/atoms/InputField";
import { format } from "date-fns";
import { id } from "date-fns/locale";
import { CSVLink } from "react-csv";
import axios from "@/plugins/axios";
import { SalesResponse } from "@/types/Reports";
import { StandardFetchResponse } from "@/types/Response";
import { forceBackendDateToWIB } from "@/utils/TextManip";
import { setStatusText } from "@/utils/transalateSalesReport";

const DownloadReportModal = () => {
  const [salesReportData, setSalesReportData] = useState<any[]>();
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [startDateInputErrorMsg, setStartDateInputErrorMsg] = useState<string>();
  const [endDateInputErrorMsg, setEndDateInputErrorMsg] = useState<string>();
  const csvLinkRef = useRef(null);

  const date: Date = new Date();
  const [showFirstCalendar, setShowFirstCalendar] = useState<boolean>(false);
  const [showSecondCalendar, setShowSecondCalendar] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<{ startDate?: Date, endDate?: Date }>({
    startDate: undefined,
    endDate: undefined
  });
  const maxEndDate: Date | undefined = !!dateRange.startDate ? new Date(new Date(dateRange.startDate).setDate(new Date(dateRange.startDate).getDate() + 30)) : undefined;

  const exportSalesReport = async (startDate?: Date, endDate?: Date) => {
    if (!!!startDate || !!!endDate) {
      if (!!!startDate) setStartDateInputErrorMsg("Tanggal awal harus diisi");
      if (!!!endDate) setEndDateInputErrorMsg("Tanggal akhir harus diisi");
      return;
    }

    setStartDateInputErrorMsg(undefined);
    setEndDateInputErrorMsg(undefined);
    setIsExporting(true);

    const ITEM_PER_PAGE: number = 100;
    const result: any[] = [];
    var totalPage: number;

    // STEP 1: FETCH TO GET TOTAL DATA TO CALCULATE TOTAL PAGE
    axios.post<StandardFetchResponse<SalesResponse>>("/admin/report/sales", {
      start_date: format(startDate, "yyyy-MM-dd"),
      end_date: format(endDate, "yyyy-MM-dd")
    }).then(async (response) => {
      totalPage = Math.ceil(response.data.total / ITEM_PER_PAGE);

      // STEP 2: THE REAL FETCH BEGINS USING FOR LOOP
      for (let currentPage = 1; currentPage <= totalPage; currentPage++) {
        await axios.post<StandardFetchResponse<SalesResponse>>("/admin/report/sales", {
          page: currentPage,
          per_page: ITEM_PER_PAGE,
          sort_create_at: true,
          start_date: format(startDate, "yyyy-MM-dd"),
          end_date: format(endDate, "yyyy-MM-dd")
        }).then((response) => {
          // PUSH EACH FETCHED DATA INTO ARRAY
          for (let i = 0; i < response.data.data.length; i++)
            result.push(response.data.data[i]);
        }).catch((error) => {
          throw new Error(`error in exportSalesReport at DownloadReportModal.tsx during fetching at Step 2. ${error}`);
        });
      }
    }).then(() => {
      // translate data into more readable state
      for (let i = 0; i < result.length; i++) {
        result[i].transaction_date = forceBackendDateToWIB(result[i].transaction_date);
        if (!!result[i].payment_date)
          result[i].payment_date = forceBackendDateToWIB(result[i].payment_date);
        result[i].status = setStatusText(result[i].status);
        // result[i].price = `${handleCurrency(result[i].price.toString())}`;
      }

      setSalesReportData(result);
    }).catch((error) => {
      throw new Error(`error in exportSalesReport at DownloadReportModal.tsx during fetching at Step 1. ${error}`);
    });
  }

  // export csv file immediately after data is ready
  // this useEffect was made because react-csv doesn't support async fetch
  useEffect(() => {
    if (!!salesReportData && !!csvLinkRef && !!csvLinkRef.current) {
      // @ts-ignore
      csvLinkRef.current.link.click();
      setSalesReportData(undefined);
      setIsExporting(false);
    }
  }, [salesReportData]);

  useEffect(() => {
    if (!!dateRange.startDate) setStartDateInputErrorMsg(undefined);
    if (!!dateRange.endDate) setEndDateInputErrorMsg(undefined);
    const daysDifference = (!!dateRange.startDate && !!dateRange.endDate) ? Math.ceil((dateRange.endDate.getTime() - dateRange.startDate.getTime()) / (1000 * 3600 * 24)) : undefined;

    if (!!daysDifference) {
      if (daysDifference < 0) setDateRange({ ...dateRange, endDate: dateRange.startDate });
      if (daysDifference > 30) setDateRange({ ...dateRange, endDate: maxEndDate });
    }
  }, [dateRange.startDate, dateRange.endDate]);

  return (
    <section className="px-24 py-16 w-[38rem] space-y-8">
      <header className="space-y-4">
        <h2 className="text-2xl font-bold">Download Laporan</h2>
        <p className="text-lg">Pilih tanggal maksimal 31 hari</p>
      </header>

      <section className="space-y-8">
        {/* start date field */}
        <div className="relative space-y-2">
          <p className="font-medium text-left text-primary-main">Dari Tanggal</p>
          <InputField
            size="md"
            readOnly
            value={!!dateRange.startDate ? format(dateRange.startDate, "dd MMMM yyyy", { locale: id }) : undefined}
            onClick={() => {
              setShowFirstCalendar(true);
              setShowSecondCalendar(false);
            }}
            error={startDateInputErrorMsg}
          />

          {showFirstCalendar && (
            <Calendar
              startDate={dateRange.startDate}
              onChange={(startDate) => {
                setDateRange({ ...dateRange, startDate: startDate ?? new Date(date.getFullYear(), date.getMonth(), 1) })
                setShowFirstCalendar(false);
              }}
              onClose={() => setShowFirstCalendar(false)}
              className="absolute top-0 z-20 -right-28"
            />
          )}
        </div>

        {/* end date field */}
        <div className="relative space-y-2">
          <p className="font-medium text-left text-primary-main">Sampai Tanggal</p>
          <InputField
            size="md"
            readOnly
            value={!!dateRange.endDate ? format(dateRange.endDate, "dd MMMM yyyy", { locale: id }) : undefined}
            onClick={() => {
              setShowFirstCalendar(false);
              setShowSecondCalendar(true);
            }}
            error={endDateInputErrorMsg}
          />

          {showSecondCalendar && (
            <Calendar
              endDate={dateRange.endDate}
              onChange={(endDate) => {
                setDateRange({ ...dateRange, endDate: endDate ?? new Date(date.getFullYear(), date.getMonth() + 1, 0) })
                setShowSecondCalendar(false);
              }}
              onClose={() => setShowSecondCalendar(false)}
              minDate={dateRange.startDate}
              maxDate={maxEndDate}
              className="absolute bottom-0 z-20 -right-28"
            />
          )}
        </div>

        {/* download button */}
        <Button size="md" widthFull onClick={() => exportSalesReport(dateRange.startDate, dateRange.endDate)} disabled={isExporting}>
          {isExporting ? "Downloading..." : "Download"}
        </Button>
        <CSVLink
          data={!!salesReportData ? salesReportData : []}
          headers={[
            { label: "Nama Owner", key: "owner_name" },
            { label: "No Telp Owner", key: "owner_phone" },
            { label: "Nama Usaha", key: "company_name" },
            { label: "No Transaksi", key: "number" },
            { label: "Tanggal Transaksi", key: "transaction_date" },
            { label: "Tanggal Pembayaran", key: "payment_date" },
            { label: "Total Bayar", key: "price" },
            { label: "Metode Pembayaran", key: "payment_type" },
            { label: "Nama Bank", key: "bank" },
            { label: "Status", key: "status" }
          ]}
          filename={`sales_trucker_${format(dateRange.startDate ?? 0, "(dd-MM-yyyy)", { locale: id })}-${format(dateRange.endDate ?? 0, "(dd-MM-yyyy)", { locale: id })}`}
          separator=";"
          className="hidden"
          ref={csvLinkRef}
        />
      </section>
    </section>
  );
}

export default DownloadReportModal;