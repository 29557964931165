import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { CSSProperties, useState } from "react";
import { GoGrabber } from "react-icons/go";
import cn from "classnames";

type sorttable_t = {
  idx: number | boolean;
  dnd?: boolean;
  id: string | number;
  children: React.ReactNode;
  isDragging?: boolean;
  enable?: boolean;
  onClick?: React.MouseEventHandler<HTMLTableRowElement>;
  isSticky?: boolean;
  goGrabberHidden?: boolean;
};
const TrSortable = ({
  id,
  children,
  enable = false,
  onClick,
  idx,
  dnd,
  goGrabberHidden = false,
}: sorttable_t) => {
  const [hover, sethover] = useState<string | number>();
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useSortable({ id });

  const style: CSSProperties = {
    // opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Transform.toString(transform),
    backgroundColor: isDragging ? "#3C4B74" : "#122045",
  };

  return (
    <>
      {enable ? (
        <tr
          ref={setNodeRef}
          style={style}
          {...attributes}
          {...listeners}
          onMouseOver={() => sethover(id)}
          onMouseOut={() => sethover(undefined)}
          className="group/tr"
        >
          {idx && (
            <td className={`sticky left-0 bg-neutral-300`}>
              <div className="flex items-center gap-3">
                <GoGrabber
                  className={`${hover == id ? "opacity-100" : "opacity-0"}`}
                />
                <p>{idx}</p>
              </div>
            </td>
          )}
          {children}
        </tr>
      ) : (
        <tr onClick={onClick} className={`${onClick && "cursor-pointer"}`}>
          {idx && (
            <td className="sticky left-0 bg-neutral-300">
              <div className="flex items-end justify-items-start gap-3">
                <GoGrabber
                  className={goGrabberHidden ? "hidden" : "opacity-0"}
                />
                <p>{idx}</p>
              </div>
            </td>
          )}
          {children}
        </tr>
      )}
    </>
  );
};

export default TrSortable;
