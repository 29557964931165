import React, { memo, useRef } from 'react'
import ErrorField from './ErrorField'

type NoImageType = {
    maksimalUpload?: string | number
    title?: string
    description?:string
    error?: string;
    helperError?: string;
    accept?:string
    statusByte?: "MB" | "KB"
}

type InputUploadFileType = {
    id: string;
    value?: File
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    defaultValue?: string
} & NoImageType

function InputUploadFile({ id, onChange, error, helperError, onBlur, value, defaultValue, maksimalUpload = 15, statusByte='MB', title = "Upload Foto Armada", description="Ekstensi gambar harus (jpg, jpeg, png, gif dan webp)", accept="image/x-png,image/gif,image/jpeg,image/webp" }: InputUploadFileType) {
    const uploadRef = useRef<any>(null);

    function openFile() {
        uploadRef.current.click()
    }

    let content:React.ReactNode
    if(error || helperError){
        content = (
            <MemoNoImage
                statusByte={statusByte}
                maksimalUpload={maksimalUpload}
                title={title}
                description={description}
                error={helperError ?? error}
            />
        )
    }else if(value && value.type.match(/image/)){
        content =  <MemoImageReady src={`${value && URL.createObjectURL(value)}`} />
    }else if(defaultValue){
        content = <MemoImageReady src={defaultValue} />
    }else{
        content = (
            <MemoNoImage
                statusByte={statusByte}
                maksimalUpload={maksimalUpload}
                title={title}
                description={description}
            />
        )
    }

    return (
        <div>
            <input
                id={id}
                onChange={onChange}
                onBlur={onBlur}
                type="file"
                style={{ display: "none" }}
                ref={uploadRef}
                accept={accept}
            />

            <div className={`bg-white-600 rounded-xl flex items-center justify-center`}>
                <div
                    className={`border-[3px] border-dashed ${(error || helperError) ? "border-danger-main" : "border-neutral-100"} rounded-[10px] flex flex-col items-center my-[31px] cursor-pointer`}
                    onClick={openFile}
                >
                    {content}
                </div>
            </div>

            {helperError && <ErrorField error={helperError} />}
        </div>
    )
}

export default memo(InputUploadFile)

const NoImage = ({ maksimalUpload, title, description, error, statusByte }: NoImageType) => (
    <div className='w-[401px] text-center'>
        <h5 className='font-medium mt-[30px]'>
            {title}
        </h5>
        <p className='text-neutral-100 text-sm font-medium mt-[10px]'>
            Maksimal (
            {maksimalUpload}
            {' '}
            {statusByte}
            )
        </p>
        <p className={`text-xs mt-[5px] mb-[30px] ${error && "text-danger-border"}`}>
            { error ?? description}
        </p>
    </div>
)
const MemoNoImage = memo(NoImage)

const ImageReady = ({ src }: { src: string }) => (
    <picture>
        <img
            src={src}
            alt=""
            width={401}
            className="rounded-[10px]"
        />
    </picture>
)
const MemoImageReady = memo(ImageReady)