import { Color } from "@/types/Style";
import { ActivityLogResponse } from "@/types/ActivityLog";

export const setBadgeColor = (type: string): Color => {
  switch (type) {
    case 'create':
    case 'update':
    case 'update_email':
    case 'update_email_otp':
    case 'update_phone':
    case 'update_phone_otp':
      return "secondary";

    case 'ban':
      return "danger";

    case 'unban':
    case 'generate_uuid':
    case 'generate_otp':
    case 'regenerate_otp':
    case 'login':
    case 'login_spam_request':
    case 'login_wrong_otp':
    case 'login_expired_otp':
    case 'login_spam_otp':
    case 'email_wrong_otp':
    case 'email_expired_otp':
    case 'email_spam_otp':
    case 'email_spam_request':
    case 'email_not_exists_otp':
    case 'phone_wrong_otp':
    case 'phone_expired_otp':
    case 'phone_spam_otp':
    case 'phone_spam_request':
    case 'phone_not_exists_otp':
      return "secondary";

    // COMPANY
    case "Tambah Contact Person":
    case "Verifikasi Contact Person":
    case "Create Post":
    case "Unbanned Post":
      return "primary";

    case "Buat Perusahaan":
    case "Pembayaran Subscription":
    case "Pembayaran Paket Berlangganan oleh Admin":
    case "Activate Post":
      return "secondary";

    case "Ubah Informasi Perusahaan":
    case "Ubah Contact Person":
    case "Update Post":
      return "warning";

    case "Delete Post":
    case "Banned Post":
    case "Hapus Contact Person":
    case "Nonaktifkan Subscription":
    case "Deactivate Post":
    case "Deactivate Post Auto":
      return "danger";

    default:
      return "primary";
  }
}

export const setBadgeText = (type: string): string => {
  switch (type) {
    case 'create': return 'Create akun baru'
    case 'update': return 'Update nama akun'
    case 'update_email': return 'Update email'
    case 'update_email_otp': return 'Update email (OTP)'
    case 'update_phone': return 'Update nomor telepon'
    case 'update_phone_otp': return 'Update nomor telepon (OTP)'
    case 'ban': return 'Blokir akun'
    case 'unban': return 'Buka blokir akun'
    case 'generate_uuid': return 'Generate UUID'
    case 'generate_otp': return 'Generate OTP'
    case 'regenerate_otp': return 'Re-generate OTP'
    case 'login': return 'Login'
    case 'login_spam_request': return 'Login spam request'
    case 'login_wrong_otp': return 'Login OTP salah'
    case 'login_expired_otp': return 'Login OTP kadaluarsa'
    case 'login_spam_otp': return 'Login spam OTP'
    case 'email_wrong_otp': return 'Update email OTP salah'
    case 'email_expired_otp': return 'Update email OTP kadalursa'
    case 'email_spam_otp': return 'Update email spam OTP'
    case 'email_spam_request': return 'Update email spam request'
    case 'email_not_exists_otp': return 'Update email OTP salah'
    case 'phone_wrong_otp': return 'Update telepon OTP salah'
    case 'phone_expired_otp': return 'Update telepon OTP kadaluarsa'
    case 'phone_spam_otp': return 'Update telepon spam OTP'
    case 'phone_spam_request': return 'Update telepon spam request'
    case 'phone_not_exists_otp': return 'Update telepon OTP salah'

    // COMPANY
    case "Buat Perusahaan": return "Lengkapi Informasi Usaha";
    case "Ubah Informasi Perusahaan": return "Ubah Informasi Usaha";

    case "Tambah Contact Person": return "Tambah Contact Person";
    case "Verifikasi Contact Person": return "Verifikasi Contact Person";
    case "Ubah Contact Person": return "Ubah Contact Person";
    case "Hapus Contact Person": return "Hapus Contact Person";

    case "Pembayaran Subscription": return "Pembayaran Paket Berlangganan";
    case "Nonaktifkan Subscription": return "Paket Berlangganan Habis";
    case "Pembayaran Paket Berlangganan oleh Admin": return "Pembayaran Paket Berlangganan oleh Admin";

    case "Create Post": return "Posting Iklan";
    case "Update Post": return "Ubah Iklan";
    case "Delete Post": return "Hapus Iklan";
    case "Banned Post": return "Blokir Iklan oleh Admin";
    case "Unbanned Post": return "Unblokir Iklan oleh Admin";
    case "Activate Post": return "Aktifkan Iklan";
    case "Deactivate Post": return "Nonaktifkan Iklan";
    case "Deactivate Post Auto": return "Nonaktifkan Iklan oleh Admin";
    case "force_paid_golek_coin": return "Force Paid Golek Koin";
    default:
      return type;
  }
}

export const setDetails = (logObject: ActivityLogResponse): JSX.Element => {
  switch (logObject.type) {


    case 'force_paid_golek_coin':
      return <p>Admin melakukan force paid transaksi golek koin</p>;

    case 'create':
      return <p>membuat akun pengguna baru</p>;
    case 'update':
      return <p>memperbarui nama akun pengguna</p>;
    case 'update_email':
      return <p>memperbarui alamat email</p>;
    case 'update_email_otp':
      return <p>generate OTP untuk memperbarui alamat email pengguna</p>;
    case 'update_phone':
      return <p>memperbarui nomor whatsapp</p>;
    case 'update_phone_otp':
      return <p>generate OTP untuk memperbarui nomor whatsapp pengguna</p>;
    case 'ban':
      return <p>memblokir akun pengguna</p>;
    case 'unban':
      return <p>membuka blokir akun pengguna</p>;
    case 'generate_uuid':
      return <p>generate UUID</p>;
    case 'generate_otp':
      return <p>generate OTP</p>;
    case 'regenerate_otp':
      return <p>generate ulang OTP</p>;
    case 'login':
      return <p>masuk ke akun pengguna</p>;
    case 'login_spam_request':
      return <p>memperbarui email pengguna dengan spam OTP</p>;
    case 'login_wrong_otp':
      return <p>masuk ke akun pengguna dengan OTP salah</p>;
    case 'login_expired_otp':
      return <p>masuk ke akun pengguna dengan OTP kadaluarsa</p>;
    case 'login_spam_otp':
      return <p>masuk ke akun pengguna dengan spam OTP</p>;
    case 'email_wrong_otp':
      return <p>memperbarui email pengguna dengan OTP salah</p>;
    case 'email_expired_otp':
      return <p>memperbarui email pengguna dengan OTP kadaluarsa</p>;
    case 'email_spam_otp':
      return <p>memperbarui email pengguna dengan spam OTP</p>;
    case 'email_spam_request':
      return <p>memperbarui email pengguna dengan spam OTP</p>;
    case 'email_not_exists_otp':
      return <p>memperbarui email dengan otp salah</p>;
    case 'phone_wrong_otp':
      return <p>memperbarui nomor telepon dengan OTP salah</p>;
    case 'phone_expired_otp':
      return <p>memperbarui nomor telepon dengan OTP kadaluarsa</p>;
    case 'phone_spam_otp':
      return <p>memperbarui nomor telepon dengan spam OTP</p>;
    case 'phone_spam_request':
      return <p>memperbarui nomor telepon dengan spam OTP</p>;
    case 'phone_not_exists_otp':
      return <p>memperbarui nomor telepon dengan OTP salah</p>


    // COMPANY
    case "Buat Perusahaan": return <p>Berhasil melengkapi Informasi Usaha</p>;
    case "Ubah Informasi Perusahaan": return <p>Informasi Usaha berhasil diubah</p>;

    case "Tambah Contact Person": return (
      <p>
        <span className="font-bold">+{logObject.extra?.phone}</span> telah ditambahkan sebagai Contact Person
      </p>
    );
    case "Verifikasi Contact Person": return (
      <p>
        <span className="font-bold">+{logObject.extra?.phone}</span> telah terverifikasi
      </p>
    );
    case "Ubah Contact Person":
      let editType: string = "No WhatsApp";
      if (!!logObject.message?.match(/nama/ig)) editType = "Nama";
      return (
        <p>
          {editType} Contact Person <span className="font-bold">{editType === "No WhatsApp" ? "+" : ""}{logObject.extra?.old}</span> telah diubah menjadi <span className="font-bold">{editType === "No WhatsApp" ? "+" : ""}{logObject.extra?.new}</span>
        </p>
      );
    case "Hapus Contact Person": return (
      <p>
        Hapus <span className="font-bold">+{logObject.extra?.phone}</span> dari Contact Person
      </p>
    );
    case "Pembayaran Subscription": return (
      <>
        <p>Pembayaran paket berlangganan berhasil</p>
        <p><span className="font-bold">No Transaksi : </span>{logObject.extra?.number}</p>
      </>
    );
    case "Nonaktifkan Subscription": return (
      <>
        <p>Masa berlaku paket berlangganan sudah habis</p>
        <p><span className="mt-4 font-bold">No Transaksi : </span>{logObject.extra?.number}</p>
      </>
    );
    case "Pembayaran Paket Berlangganan oleh Admin": return (
      <>
        <p><span className="mt-4 font-bold">Alasan : </span>{logObject.message}</p>
        <p><span className="mt-4 font-bold">No Transaksi : </span>{logObject.extra?.number}</p>
      </>
    );

    case "Create Post":
    case "Update Post":
    case "Delete Post":
    case "Activate Post":
    case "Deactivate Post": return (
      <>
        <p><span className="font-bold">Judul Iklan : </span>{logObject.extra?.title}</p>
        <p><span className="font-bold">ID Iklan : </span>{logObject.extra?.post_id}</p>
      </>
    );
    case "Deactivate Post Auto": return (
      <>
        <p>Iklan telah dinonaktifkan langsung oleh sistem</p>
        <p><span className="font-bold">Judul Iklan : </span>{logObject.extra?.title}</p>
        <p><span className="font-bold">ID Iklan : </span>{logObject.extra?.post_id}</p>
      </>
    );
    case "Banned Post":
    case "Unbanned Post": return (
      <>
        <p><span className="font-bold">Judul Iklan : </span>{logObject.extra?.title}</p>
        <p><span className="font-bold">ID Iklan : </span>{logObject.extra?.post_id}</p>
        <p><span className="font-bold">Alasan : </span>{logObject.message}</p>
      </>
    );

    default:
      return <p>{logObject.type}_details</p>;
  }
}