import { useState } from "react";
import { ColumnTable } from "./Tabel";
import SortingTable from "../atoms/SortingTable";

type RawTabelType<T> = {
  column: ColumnTable<T>;
  record: T[];
  page: number;
  per_page: number;
  onSort?: (sort: string, by: string) => void;
};

const RawTabel = <T,>({
  column,
  record,
  page,
  per_page,
  onSort,
}: RawTabelType<T>) => {
  const [hover, setHover] = useState<string>();

  const [sort, setSort] = useState("");
  const [by, setBy] = useState<"asc" | "desc">("asc");

  const handleSort = (v: string) => {
    if (by == "asc") {
      setSort(v);
      setBy("desc");
      onSort && onSort(v, "desc");
    } else {
      setSort(v);
      setBy("asc");
      onSort && onSort(v, "asc");
    }
  };

  return (
    <table id="tabel" className={`w-full`}>
      <thead>
        <tr className="bg-white-600 ">
          <th className={`sticky left-0 py-3 capitalize`}>
            <p className={`text-center`}>No</p>
          </th>

          {column.map((v, i) => {
            return (
              <th
                key={i}
                className={`${v.headerStyle} ${v.isSticky && "sticky left-0"}`}
                onMouseOver={() => setHover(v.accessor.toString())}
                onMouseLeave={() => setHover(undefined)}
              >
                <button
                  className="flex items-center justify-between gap-5 capitalize"
                  onClick={() =>
                    v.sortTable && handleSort(v.accessor.toString())
                  }
                >
                  <p>{v.header}</p>

                  {v.sortTable && (
                    <picture>
                      <img
                        src="/icon/arrow-up.svg"
                        alt="sort icon"
                        className={`transition-all duration-300 ${
                          sort == v.accessor ? "opacity-100" : "opacity-0"
                        } ${hover == v.accessor && "opacity-90"} ${
                          by == "desc" && "rotate-180"
                        }`}
                      />
                    </picture>
                  )}
                </button>
              </th>
            );
          })}
        </tr>
      </thead>

      <tbody>
        {record &&
          record.map((datas: any, i: number) => {
            return (
              <tr key={i}>
                <td className="text-center">
                  {" "}
                  {i + page * per_page - per_page + 1}
                </td>
                {column.map((c, index) => {
                  if (c.accessor == "skip") return null;
                  //@ts-ignore
                  var fieldData: any = datas[c.accessor] as any;

                  return (
                    <td
                      key={index}
                      onClick={(e) => {
                        if (c.stopPropagation == true) {
                          e.stopPropagation();
                        }
                      }}
                      className={`py-3`}
                    >
                      {!!c.accessorComponent
                        ? c.accessorComponent(datas)
                        : fieldData ?? "-"}
                    </td>
                  );
                })}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default RawTabel;
