import React, { useState } from "react";
import { useMemo } from "react";
import useFilter from "store/useFilter";

type type = {
  totalCount: number;
  siblingCount?: number;
};

export const DOTS = "...";

const range = (start: any, end: any) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({ siblingCount = 1, totalCount }: type) => {
  const {
    filter: { page, per_page },
  } = useFilter();

  const paginationRange = useMemo(() => {
    // total page
    const pages = Math.ceil(totalCount / per_page);

    // Pages count is determined as siblingCount + firstPage + lastPage + page + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    /*
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..pages]
    */
    if (totalPageNumbers > pages) {
      return range(1, pages);
    }

    const leftSiblingIndex = Math.max(page - siblingCount, 1);
    const rightSiblingIndex = Math.min(page + siblingCount, pages);

    /*
    We do not want to show dots if there is only one position left 
    after/before the left/right page count as that would lead to a change if our Pagination
    component size which we do not want
    */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < pages - 2;

    const firstPageIndex = 1;
    const lastPageIndex = pages;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, pages];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(pages - rightItemCount + 1, pages);

      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);

      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [totalCount, per_page, siblingCount, page]);

  return paginationRange;
};

type typeRaw = {
  totalCount: number;
  siblingCount?: number;
  page: number,
  per_page: number
};

export const usePaginationRaw = ({ siblingCount = 1, totalCount, page, per_page }: typeRaw) => {


  const paginationRange = useMemo(() => {
    // total page
    const pages = Math.ceil(totalCount / per_page);

    // Pages count is determined as siblingCount + firstPage + lastPage + page + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    /*
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..pages]
    */
    if (totalPageNumbers > pages) {
      return range(1, pages);
    }

    const leftSiblingIndex = Math.max(page - siblingCount, 1);
    const rightSiblingIndex = Math.min(page + siblingCount, pages);

    /*
    We do not want to show dots if there is only one position left 
    after/before the left/right page count as that would lead to a change if our Pagination
    component size which we do not want
    */
    const shouldShowLeftDots = leftSiblingIndex > 3;
    const shouldShowRightDots = rightSiblingIndex < pages - 2;

    const firstPageIndex = 1;
    const lastPageIndex = pages;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, pages];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(pages - rightItemCount + 1, pages);
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex - 1, rightSiblingIndex + 1);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [totalCount, per_page, siblingCount, page]);

  return paginationRange;
};
