import { ReactNode, useMemo } from "react";
import { Color, Size } from "../../types/Style";

type Props = {
  children: ReactNode;
  circular?: boolean;
  size?: Size;
  color?: Color | "white" | "gray";
  onClick?: () => void;
  disabled?: boolean;
  addClassName?: string;
};

const Tags = ({ children, size = "sm", color = "primary", onClick, disabled = false, addClassName }: Props) => {
  const sizing = useMemo(() => {
    switch (size) {
      case "sm":
        return "py-1 px-3 text-sm rounded-full";
      case "md":
        return "py-2 px-4 text-base rounded-lg";
      case "lg":
        return "py-3 px-5 text-base rounded-lg";
    }
  }, [size]);

  const colorSet = useMemo(() => {
    if (disabled) {
      return "bg-white-400 text-white-500 border-white-400";
    } else {
      switch (color) {
        case "primary":
          return "bg-primary-surface text-primary-pressed border-primary-pressed";
        case "secondary":
          return "bg-secondary-surface text-secondary-pressed border-secondary-pressed";
        case "success":
          return "bg-success-surface text-success-pressed border-success-pressed";
        case "warning":
          return "bg-warning-surface text-warning-pressed border-warning-pressed";
        case "danger":
          return "bg-danger-surface text-danger-pressed border-danger-pressed";
        case "gray":
          return "bg-white-300 text-white-500 border-white-400";
        case "white":
          return "text-white-0 border-white-0";
      }
    }
  }, [color, disabled]);
  return (
    <div
      className={`inline ${colorSet} ${sizing} border-2 text-center font-semibold ${
        !disabled && "cursor-pointer"
      } ${addClassName}`}
      onClick={() => !disabled && onClick?.()}
    >
      {children}
    </div>
  );
};

export default Tags;
