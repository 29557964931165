import { SelectBox } from "@/components/molecules";
import useClickOutside from "hooks/useClickOutside";
import { Dispatch, SetStateAction, useRef } from "react";

import Inputfield from "../InputField";
import { DateTypes, monthName } from "../../../hooks/useCalendar";
import { handleNumber } from "utils/TextManip";

// For selectbox options
let monthOption = () =>
  monthName.map((month, index) => ({
    label: month,
    value: index,
  }));

type SelectYearAndMonthProps = {
  show: boolean;
  date: Omit<DateTypes, "day">;
  setDate: Dispatch<SetStateAction<Omit<DateTypes, "day">>>;
  tempYear: number;
  setTempYear: Dispatch<SetStateAction<number>>;
  onClickOutside: () => void;
};

const SelectYearAndMonth = ({
  show,
  date,
  setDate,
  tempYear,
  setTempYear,
  onClickOutside,
}: SelectYearAndMonthProps) => {
  const selectRef = useRef(null);
  useClickOutside(selectRef, () => show && onClickOutside());
  if (!show) return null;
  return (
    <div
      className="bg-neutral-500 absolute py-2 px-3 rounded-lg w-full pb-4 "
      ref={selectRef}
    >
      <div className="font-normal text-primary-main">Bulan</div>
      <SelectBox
        options={monthOption()}
        className="inline-block w-full my-2"
        selectedValue={date.month}
        size="sm"
        onChange={(e) => setDate({ ...date, month: e })}
        blackVariant
      />
      <div className="font-normal text-primary-main">Tahun</div>
      <Inputfield
        type="number"
        value={tempYear}
        onChange={(e) =>
          setTempYear(
            Number(handleNumber(e.target.value)) ?? new Date().getFullYear()
          )
        }
        onKeyUp={(e) =>
          e.key === "Enter" && setDate({ ...date, year: tempYear })
        }
        onBlur={() => {
          if (tempYear < 1970 || tempYear > 2077) setTempYear(date.year);
          else setDate({ ...date, year: tempYear });
        }}
        placeholder="Tahun"
        id="whatamisupposedtoputinthere"
        size="sm"
        min={1970}
        max={2077}
      />
    </div>
  );
};

export default SelectYearAndMonth;
