import { usePagination, DOTS, usePaginationRaw } from "hooks/usePagination";
import React, { useState } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import useFilter from "store/useFilter";
import style from "../../styles/datatable.module.scss";

// edit on:
// https://stackblitz.com/edit/react-1zaeqk?file=src%2FPagination.js
type pagination_t = {
  data: number;
  refetch?: () => void;
  isFetching?: boolean;
  paginationClickScrollTop?: boolean;
};

const Pagination = ({
  data,
  refetch,
  isFetching,
  paginationClickScrollTop,
}: pagination_t) => {
  const {
    filter: { page },
    setFilter,
  } = useFilter();

  const paginationRange = usePagination({
    totalCount: data,
  });

  // @ts-ignore
  if (!data || page === 0 || paginationRange?.length < 2) {
    return null;
  }

  const onNext = () => {
    setFilter({ page: page + 1 }).then(() => refetch && refetch());
  };

  const onPrev = () => {
    setFilter({ page: page - 1 }).then(() => refetch && refetch());
  };
  const handleScrollTop = () => {
    const element = document.getElementById("tablewrapper");

    if (element && paginationClickScrollTop) element.scrollTo(0, 0);
  };

  // @ts-ignore
  let lastPage =
    paginationRange && paginationRange[paginationRange?.length - 1];

  return (
    <>
      <ul className="flex items-center gap-3">
        <li>
          <button disabled={page == 1 || isFetching} onClick={onPrev}>
            <MdKeyboardArrowLeft className="mt-2 text-4xl" />
          </button>
        </li>
        {paginationRange?.map((p, i) => {
          if (p === DOTS) {
            return <li key={i}>&#8230;</li>;
          }

          return (
            <li key={i}>
              <button
                disabled={isFetching}
                onClick={() =>
                  setFilter({ page: p as number }).then(() => {
                    refetch?.();
                    handleScrollTop();
                  })
                }
                className={`h-11 w-11 rounded-md hover:bg-primary-main ${
                  page == p && style.paginationActive
                }`}
              >
                {p}
              </button>
            </li>
          );
        })}
        <li>
          <button onClick={onNext} disabled={page == lastPage || isFetching}>
            <MdKeyboardArrowRight className="mt-2 text-4xl" />
          </button>
        </li>
      </ul>
    </>
  );
};

export default Pagination;

type RawPaginationType = {
  data: number;
  page: number;
  perPage: number;
  onChange: (arg0: number) => void;
};

export const RawPagination = ({
  data,
  perPage,
  page,
  onChange,
}: RawPaginationType) => {
  const paginationRange = usePaginationRaw({
    totalCount: data,
    page,
    per_page: perPage,
  });

  // @ts-ignore
  if (!data || page === 0 || paginationRange?.length < 2) {
    return null;
  }

  const onNext = () => {
    onChange(page + 1);
  };

  const onPrev = () => {
    onChange(page - 1);
  };

  // @ts-ignore
  let lastPage =
    paginationRange && paginationRange[paginationRange?.length - 1];

  return (
    <>
      <ul className="flex items-center gap-3">
        <li>
          <button disabled={page == 1} onClick={onPrev}>
            <MdKeyboardArrowLeft className="mt-2 text-4xl" />
          </button>
        </li>
        {paginationRange?.map((p, i) => {
          if (p === DOTS) {
            return <li key={i}>&#8230;</li>;
          }

          return (
            <li key={i}>
              <button
                onClick={() => onChange(p as number)}
                className={`h-11 w-11 rounded-md hover:bg-primary-main ${
                  page == p && style.paginationActive
                }`}
              >
                {p}
              </button>
            </li>
          );
        })}
        <li>
          <button onClick={onNext} disabled={page == lastPage}>
            <MdKeyboardArrowRight className="mt-2 text-4xl" />
          </button>
        </li>
      </ul>
    </>
  );
};
