import { useState, useEffect, useRef, useMemo } from "react";
import { HiCheck, HiChevronDown } from "react-icons/hi";
import { Option } from "types/SelectBoxOption";
import { Size } from "types/Style";
import setComponentSize from "utils/setComponentSize";
import ErrorField from "../atoms/ErrorField";
import { MdClose } from "react-icons/md";
import useClickOutside from "@/hooks/useClickOutside";

type conditionalOnChange<T, TClearable = boolean> = TClearable extends true
  ? {
      clearable: TClearable;
      onChange?: (v: T | undefined) => void;
    }
  : {
      clearable?: TClearable;
      onChange?: (v: T) => void;
    };

export type SelectBoxType<T> = {
  options: Option<T>[];
  name?: string;
  size?: Size;
  label?: string;
  blackVariant?: boolean; // use black theme, otherwise purple theme
  className?: string;
  openUpward?: boolean; // make selectbox to open upward
  selectedValue?: T;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  error?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
} & conditionalOnChange<T>;

const setSelectBoxTheme = (isBlackVariant?: boolean): string => {
  if (isBlackVariant) return "bg-white-600 text-white-400";
  else return "";
};

const setSelectBoxItemTheme = (
  isBlackVariant: boolean | undefined,
  currentIndex: number,
  activeIndex: number
): string => {
  if (!isBlackVariant) return "bg-primary-pressed";
  else
    return `hover:bg-primary-pressed hover:text-white-0 ${
      currentIndex === activeIndex
        ? "bg-primary-focus text-primary-main"
        : "text-white-400 bg-neutral-200"
    }`;
};

//* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
// =-=-=-=-=-=-=-=-=-= SELECTBOX COMPONENT STARTS HERE =-=-=-=-=-=-=-=-=-=-
//* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
const SelectBox = <T extends string | number>({
  options,
  name,
  size = "sm",
  label,
  blackVariant,
  readOnly,
  className,
  openUpward,
  selectedValue, // set value manually. Use this prop to manipulate value by external function e.g formik handleReset
  placeholder,
  disabled,
  onChange,
  clearable,
  error,
  onBlur,
}: SelectBoxType<T>) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const selectBoxRef = useRef<HTMLDivElement | null>(null);
  const selectedItemRef = useRef<HTMLDivElement | null>(null);

  // scroll to selected item (to nearest bottom of the parent)
  useEffect(() => {
    if (!!selectBoxRef.current && !!selectedItemRef.current) {
      selectBoxRef.current.scrollTop =
        selectedItemRef.current.offsetTop -
        selectBoxRef.current.offsetHeight +
        selectedItemRef.current.offsetHeight;
    }
  }, [isOpened]);

  const activeIndex = useMemo(() => {
    return options.findIndex((e) => e.value === selectedValue);
  }, [selectedValue]);

  const autoOpenUpward = (): boolean => {
    if (
      !!containerRef.current &&
      window.innerHeight - containerRef.current?.getBoundingClientRect().y <
        210 &&
      openUpward === undefined
    )
      return true;
    else return false;
  };

  const handleOnClick = (value: any, index: number) => {
    onChange?.(value);
    setIsOpened(false);
  };

  const setAbsolutePosition = (
    size: Size,
    openUpward: boolean | undefined
  ): string => {
    switch (size) {
      case "sm":
        if (openUpward ?? autoOpenUpward())
          return "bottom-10 animate-fade-in-slide-up";
        else return "top-10 animate-fade-in-slide-down";
      case "md":
        if (openUpward ?? autoOpenUpward())
          return "bottom-12 animate-fade-in-slide-up";
        else return "top-12 animate-fade-in-slide-down";
      case "lg":
        if (openUpward ?? autoOpenUpward())
          return "bottom-15 animate-fade-in-slide-up";
        else return "top-15 animate-fade-in-slide-down";
      default:
        return "";
    }
  };

  const handleStyleDisabled = () => {
    if (disabled) {
      return "bg-white-500 text-white-400";
    } else if (!blackVariant) {
      return "bg-neutral-300 text-white-500";
    } else {
      return "bg-white-600 text-white-500";
    }
  };

  useClickOutside(containerRef, () => {
    if (!isOpened) return;
    setIsOpened(false);
  });

  return (
    <section
      // tabIndex={0} // to enable onBlur event
      onBlur={() => setIsOpened(false)}
      className={`${label ? "flex items-center gap-3 " : ""}select-none grow`}
      ref={containerRef}
    >
      {label && (
        // eslint-disable-next-line react/jsx-one-expression-per-line
        <p>{label}</p>
      )}

      <div className={`relative flex-grow ${className}`}>
        {/* active item */}
        <div
          onClick={() => !disabled && !readOnly && setIsOpened((prev) => !prev)}
          className={`flex cursor-pointer items-center justify-between truncate rounded-md border-2
           ${error ? "border-danger-main" : "border-primary-main"} ${
            !error && !isOpened && blackVariant && "border-opacity-0"
          } ${
            disabled ? handleStyleDisabled() : setSelectBoxTheme(blackVariant)
          } ${setComponentSize(size)}`}
        >
          <p className="truncate">
            {options.find((e) => e.value === selectedValue)?.label ??
              placeholder}
          </p>
          {!!selectedValue && clearable ? (
            <MdClose
              className={`shrink-0 ${
                disabled ? "cursor-auto" : "cursor-pointer"
              }`}
              onClick={(e) => {
                e.stopPropagation();
                !disabled && onChange?.(undefined);
              }}
            />
          ) : (
            <HiChevronDown
              className={`${isOpened ? "rotate-180" : "rotate-0"} shrink-0`}
            />
          )}
        </div>

        {/* =-=-=-=-= items list =-=-=-=-= */}
        <div
          ref={selectBoxRef}
          className={`absolute left-0 right-0 z-30 max-h-48 overflow-y-auto ${
            !blackVariant
              ? "rounded-md border-2 border-primary-main bg-primary-pressed"
              : "bg-[#1E1E1E]"
          } overflow-hidden ${
            isOpened
              ? `${setAbsolutePosition(size, openUpward)} visible`
              : "invisible"
          }`}
        >
          {options.map((option: Option<T>, i: number) => (
            <div
              ref={i === activeIndex ? selectedItemRef : null}
              className={`
                cursor-pointer hover:bg-primary-main
                ${setSelectBoxItemTheme(blackVariant, i, activeIndex)}
              `}
              key={`item-${i}`}
            >
              <label
                className={`${setComponentSize(
                  size
                )} flex h-full w-full cursor-pointer items-center justify-between truncate`}
              >
                <input
                  defaultChecked={i === activeIndex}
                  className="hidden"
                  type="radio"
                  name={name}
                  value={option.value}
                  onClick={() => !disabled && handleOnClick(option.value, i)}
                  // onChange={onChange}
                  readOnly
                  disabled={disabled}
                  id={option.label}
                  onBlur={onBlur}
                />
                <p className="truncate">{option.label}</p>
                {activeIndex === i && <HiCheck className="shrink-0" />}
              </label>

              {/* line */}
              {!blackVariant && (
                <div
                  className={`mx-4 block border-white-500 ${
                    i + 1 !== options.length && "border-b-2"
                  }`}
                />
              )}
            </div>
          ))}
        </div>
      </div>

      {error && <ErrorField error={error} />}
    </section>
  );
};

export default SelectBox;
