import useFilter from "@/store/useFilter";
import { useEffect } from "react";
import cn from "classnames";

type SortingTableType = {
  accessor: any;
  hover?: string;
  defaultSort?: boolean;
  defaultBy?: "asc" | "desc";
};

export default function SortingTable({
  accessor,
  hover,
  defaultBy,
  defaultSort,
}: SortingTableType) {
  const {
    filter: { sort, by },
    setFilter,
  } = useFilter();

  useEffect(() => {
    if (defaultSort && !sort) {
      setFilter({ sort: accessor, by: defaultBy });
    }
  }, [accessor, defaultSort, defaultBy, setFilter, sort]);

  return (
    <picture>
      <img
        src="/icon/arrow-up.svg"
        alt="sort icon"
        className={cn(
          "transition-all duration-300",
          sort == accessor ? "opacity-100" : "opacity-0",
          hover == accessor && "opacity-0",
          by == "desc" && "rotate-180"
        )}
      />
    </picture>
  );
}
