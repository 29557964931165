import Tags from "../atoms/Tags";
import { IoMdPerson } from "react-icons/io";
import { formatBackendDateOnly, formatBackendTimeOnly } from "@/utils/TextManip";
import { setBadgeColor, setBadgeText, setDetails } from "@/utils/translateActivityLog";
import { ActivityLogResponse } from "@/types/ActivityLog";

type Props = {
  logObject: ActivityLogResponse;
}

const LogItem = ({ logObject }: Props) => {
  const actorIsAdmin: boolean = !!logObject.admin_name;

  return (
    <section className="flex items-center gap-12 justify-evenly">
      {/* =-=-=-=-=-=-=-=-=-=-=-= DATE =-=-=-=-=-=-=-=-=-=-=-= */}
      <article className="text-center basis-1/6 shrink-0">
        <p className="font-semibold">
          {formatBackendDateOnly(logObject.date)}
        </p>
        <p className="text-sm font-medium">
          {formatBackendTimeOnly(logObject.date)}
        </p>
      </article>
      {/* =-=-=-=-=-=-=-=-=-=-=-= END OF DATE =-=-=-=-=-=-=-=-=-=-=-= */}


      {/* =-=-=-=-=-=-=-=-=-=-=-= CARD =-=-=-=-=-=-=-=-=-=-=-= */}
      <section className="w-4/5 bg-[#2B2D65] py-[1.125rem]  px-12 gap-12 border-2 flex  items-center border-primary-pressed rounded-[0.625rem]">
        <div className="flex items-center flex-1 gap-3">
          {/* avatar */}
          <div className={`flex items-center justify-center w-10 h-10 rounded-full ${actorIsAdmin ? "bg-primary-main" : "bg-secondary-main"}`}>
            <IoMdPerson className="w-6 h-6" />
          </div>

          {/* name */}
          <p className={`font-semibold ${actorIsAdmin ? "text-primary-border" : "text-secondary-main"}`}>
            {actorIsAdmin ? logObject.admin_name : "User"}
          </p>
        </div>

        <div className="flex-1 space-y-2">
          <Tags color={setBadgeColor(logObject.type)}>
            {setBadgeText(logObject.type)}
          </Tags>

          <article className="text-sm font-semibold">
            {setDetails(logObject)}
          </article>
        </div>
      </section>
      {/* =-=-=-=-=-=-=-=-=-=-=-= ^^ END OF CARD ^^ =-=-=-=-=-=-=-=-=-=-=-= */}
    </section>
  );
}

export default LogItem;